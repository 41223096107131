
import React from 'react';
import "./App.css";
import "./assect/flexible";

import logo from './images/logo.png'
import apple from './images/apple.png'
import pictureOne from './images/picture-1.png'
import pictureTwo from './images/picture-2.png'
import pictureThree from './images/picture-3.png'

function App() {
  return (
    <div className="main">
      <div>
        {/* 背景 */}
        <div className="bg" />
        {/* 内容区域 */}
        <div className="content">
          {/* 图标 */}
          <img src={logo} alt="图标" className="icon"></img>
          <h5 className="title-h5">ArtTune</h5>
          <h2 className="title-h2">Photo&Video Maker!</h2>
          {/* 下载方式 */}
          <div>
            <a
              href="https://apps.apple.com/app/id1531485845"
              className="appleTarget"
            >
              <img src={apple} alt="apple" className="appleImg"></img>
            </a>
          </div>
        </div>
      </div>

      <div className="imgs">
        <img src={pictureThree} alt="slice1"></img>
        <img src={pictureTwo} alt="slice2"></img>
        <img src={pictureOne} alt="slice3"></img>
      </div>

      {/* 尾部 */}
      <div className="footer">
        <span className="contact">
          <div>Contact us</div>
          <div className="pop">
            If you have some problem when using Arttune, you can tell us in Instagram or Email us:
            <br />
            <span>Arttuneapp@gmail.com</span>
          </div>
        </span>
        <a href='https://sbf-rc.vllresource.com/web/h5template/e8f29e51-850d-45f6-bfc0-8f613db17a86-language=en/dist/index.html'>
          Privacy Policy
        </a>
        <a href='https://rc-sgp.arttuneext.com/web/h5template/3ac7c6a9-bdee-45a0-a066-4799a5b54d2c-language=en/dist/index.html'>
          Terms of use
        </a>
      </div>
      <div className="icp">
        Copyright © ArtTune 2021.All Rights Reserved
        {/* <a href='https://beian.miit.gov.cn/'></a> */}
      </div>
    </div>
  );
}

export default App;
